import { useRouter } from 'next/router';
import * as queryString from 'query-string';
import { ShapesEnum, LandingPageContextEnum } from 'enums';
import CreationService from 'services/CreationService';
import ProfileService from 'services/ProfileService';
import { getEnv } from 'utils';
import useIsMobile from './useIsMobile';

export function makeRequestPayloadFromShape(shape, width, height) {
  switch (shape) {
    case ShapesEnum.ABSTRACT:
      return { themeSlug: 'blank-500x200' };
    case ShapesEnum.SLIDE:
      return { themeSlug: 'blank-800x600-' };
    case ShapesEnum.INFOGRAPHIC:
      return { themeSlug: 'blank-a4' };
    case ShapesEnum.POSTER:
      return {
        themeSlug: 'blank-a4',
        with: 711,
        height: 948,
      };
    case ShapesEnum.CUSTOM:
      return {
        themeSlug: 'blank-800x600-',
        width,
        height,
      };
    default:
      throw new Error(`Failed to make request payload for unknown shape [${shape}]`);
  }
}

export default function useCreationCreate() {
  const router = useRouter();
  const isMobile = useIsMobile();

  async function verifyProfileUser(data) {
    const profileService = new ProfileService();
    const { data: profile } = await profileService.fetchOne(data?.id);
    const { data: user } = await profileService.getUser(data?.id);

    if (user.login >= 2) {
      if (!profile.idStudyField || !profile.idRole || !profile.institution) {
        router.push('/auth/complete-profile');
        return true;
      } else if (profile.idStudyField && profile.idRole && profile.institution) {
        if (isMobile) {
          router.push('/unsupported-device');
          return true;
        }

        router.push('/my-creations');
        return true;
      }
      return false;
    }
  }

  function makeRequestPayloadFromLandingPageContext(context) {
    switch (context) {
      case LandingPageContextEnum.GRAPHICAL_ABSTRACT_MAKER:
        return {
          themeSlug: 'birds',
          width: 500,
          height: 280,
        };
      case LandingPageContextEnum.POSTER_MAKER:
        return {
          themeSlug: 'poster-maker-neutral-template',
          width: 711,
          height: 948,
        };
      case LandingPageContextEnum.CREATE_GRAPHICAL_ABSTRACT:
      case LandingPageContextEnum.SCIENCE_FIGURES:
      case LandingPageContextEnum.KOREA_TECH:
      case LandingPageContextEnum.HOME_PAGE:
        return {
          themeSlug: 'template-onboarding-1',
          width: 500,
          height: 280,
        };
      default:
        return { themeSlug: 'blank-800x600-' };
    }
  }

  async function createCreation(data, onboarding = false) {
    const { data: creation } = await CreationService.create(data);
    const queryParams = {};

    if (onboarding) {
      queryParams.onboarding = true;
    }

    router.push(`/workspace/${creation.id}?${queryString.stringify(queryParams)}`);
  }

  async function createCreationFromShape(shape, width, height) {
    const { data: creation } = await CreationService.create(
      makeRequestPayloadFromShape(shape, width, height),
    );

    router.push(`/workspace/${creation.id}`);
  }

  async function createCreationFromLandingPageContext(context, data) {
    const redirectedToCompleteProfile = await verifyProfileUser(data);

    if (!redirectedToCompleteProfile && isMobile) {
      router.push('/unsupported-device');
      return;
    }

    if (!redirectedToCompleteProfile) {
      const { data: creation } = await CreationService.create(
        makeRequestPayloadFromLandingPageContext(context),
      );

      router.push(`/workspace/${creation.id}?onboarding=true`);
    }
  }

  async function createCreationFromLandingPageContextInfographic(context, data) {
    const redirectedToCompleteProfile = await verifyProfileUser(data);

    if (!redirectedToCompleteProfile) {
      const { data: creation } = await CreationService.create(context);
      router.push(`/workspace/${creation.id}?first_access=true&onboarding=true`);
    }
  }

  async function createCreationFromLandingPageContextNotFirstAccess(context) {
    const { data: creation } = await CreationService.create(
      makeRequestPayloadFromLandingPageContext(context),
    );

    router.push(`/workspace/${creation.id}`);
  }

  async function createCreationFromIconRequest(context, params) {
    const { data: creation } = await CreationService.create(
      makeRequestPayloadFromLandingPageContext(context),
    );

    const redirectUrl = `${getEnv('MTG_URL_APP')}/workspace/${creation.id}?${params}`;

    return redirectUrl;
  }
  return {
    createCreation,
    createCreationFromShape,
    createCreationFromLandingPageContext,
    createCreationFromLandingPageContextInfographic,
    createCreationFromLandingPageContextNotFirstAccess,
    createCreationFromIconRequest,
  };
}
