import clsx from 'clsx';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import { useRouter } from 'next/router';
import { LandingPageContextEnum, PlanEditableCreationsEnum } from 'enums';
import { useCreations } from 'contexts/useCreations';
import useCreationCreate from 'hooks/useCreationCreate';
import Notification from 'components/Notification';
import { useStyles } from './styles';
import { useTemplate } from 'contexts/TemplateContext';
import { useModal } from 'contexts/EmailFormModalContext';
import useIsMobile from 'hooks/useIsMobile';
import { OneLoginService } from 'services/AuthService';
import useGeoData from 'hooks/useGeoData';
import Link from 'next/link';
import { useAuthUser } from 'features/auth/AuthUserContext';
import { EditageConfig } from 'config/onelogin.config';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';

const Props = {
  context: PropTypes.oneOf(Object.values(LandingPageContextEnum)).isRequired,
  buttonColor: PropTypes.oneOf(['primary', 'white', 'purple', 'pink', '#7833ff']),
  textButtonLogged: PropTypes.string,
  textButtonNotLogged: PropTypes.string,
  templatesPage: PropTypes.bool,
};

const DefaultProps = {
  textButtonLogged: 'START CREATING NOW',
  textButtonNotLogged: 'Get Started now',
  labelColor: 'grey',
  textAgreeColor: '#212121',
};

export default function EmailFormVariant({
  context,
  buttonLoggedColor,
  textButtonLogged,
  textButtonNotLogged,
  buttonColor,
  labelColor,
  textAgreeColor,
}) {
  const classes = useStyles({ labelColor });
  const router = useRouter();
  const { user, isAuthenticated } = useAuthUser();
  const [allCreations] = useCreations();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { template } = useTemplate();
  const { setIsModalOpen } = useModal();
  const { createCreationFromLandingPageContextNotFirstAccess } = useCreationCreate();
  const { createCreation } = useCreationCreate();
  const isMobile = useIsMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState('');
  const { geoData } = useGeoData();
  const { gaIdentifiers } = useGoogleAnalytics() || {};

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const { client_id, session_id } = gaIdentifiers || {};
    const countryCode = geoData?.country_code;
    const domain =
      countryCode === 'CN' ? EditageConfig.sso.chineseDomain : EditageConfig.sso.mainDomain;

    try {
      const response = await OneLoginService.shortSignup({
        email,
        firstName,
        lastName,
        geoData,
      });

      if (response.data.userExists) {
        const continueUrl = `${process.env.MTG_URL_APP}${router.pathname}`;
        let redirectUrl = `${domain}/login?continue=${encodeURIComponent(continueUrl)}`;

        if (gaIdentifiers) {
          redirectUrl += `&clientId=${encodeURIComponent(client_id)}&sessionId=${encodeURIComponent(session_id)}`;
        }

        router.push(redirectUrl);
        return;
      }

      const continueTo =
        router.pathname === '/' ? `${process.env.MTG_URL_APP}/my-creations` : window.location.href;

      await router.push(`${response.data.autoLoginURL}?continue=${encodeURIComponent(continueTo)}`);
    } catch (e) {
      setIsLoading(false);
      setNotification('Login failed, please try again.');
    }
  }

  function handleRedirectToOldWorkspace() {
    window.location.href = `${getEnv('MTG_URL_LEGACY')}/workspace/create_base_slide/${
      template.slug
    }/${template.width}/${template.height}`;
  }

  async function handleButtonClick() {
    if (isMobile) {
      router.push('/unsupported-device');
      return;
    }

    if (allCreations.length >= PlanEditableCreationsEnum[user.tp_user]) {
      router.push('/my-creations');
      return;
    }

    if (!user.useNewWorkspace) {
      handleRedirectToOldWorkspace();
      return;
    }

    try {
      if (router.pathname.includes('/infographic-templates/')) {
        await createCreation({ themeSlug: template.slug });
      } else {
        await createCreationFromLandingPageContextNotFirstAccess(context);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsModalOpen(false);
    }
  }

  if (!isAuthenticated) {
    return (
      <form className={classes.root} onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="row" gap={2}>
          <TextField
            variant="standard"
            fullWidth
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.inputFocused,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.inputLabelFocused,
              },
            }}
            label="Enter your name"
            onChange={({ target: { value } }) => setFirstName(value)}
            value={firstName}
            required
            name="firstName"
          />

          <TextField
            variant="standard"
            fullWidth
            InputProps={{
              classes: {
                root: classes.input,
                focused: classes.inputFocused,
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: classes.inputLabelFocused,
              },
            }}
            onChange={({ target: { value } }) => setLastName(value)}
            value={lastName}
            label="Enter your surname"
            required
          />
        </Box>
        <TextField
          variant="standard"
          fullWidth
          InputProps={{
            classes: {
              root: classes.input,
              focused: classes.inputFocused,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes.inputLabel,
              focused: classes.inputLabelFocused,
            },
          }}
          label="Enter your e-mail"
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          required
          type="email"
        />
        <div>
          <Button
            className={clsx(classes.button, {
              [classes.btnUserPink]: buttonColor === 'pink',
              [classes.buttonTextPurple]: buttonColor === '#7833ff',
              [classes.btnWhite]: buttonColor === 'white',
            })}
            variant="contained"
            type="submit"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            {textButtonNotLogged}
          </Button>
          <Box mt={1}>
            <small style={{ color: textAgreeColor }}>
              By signing up for this you agree to our{' '}
              <Link href="https://cactusglobal.com/terms-of-use/">Terms of Use</Link>.
            </small>
          </Box>
        </div>
        <Notification
          isOpen={!!notification}
          message={notification}
          onClose={() => setNotification('')}
        />
      </form>
    );
  }

  return (
    <Button
      className={clsx(classes.buttonLogged, {
        [classes.btnUserLoggedBlack]: buttonLoggedColor === 'primary',
        [classes.btnUserLoggedPurple]: buttonLoggedColor === 'purple',
        [classes.btnWhite]: buttonLoggedColor === 'white',
        [classes.btnLoggedDefault]: buttonLoggedColor === 'default',
      })}
      onClick={handleButtonClick}
    >
      {textButtonLogged}
    </Button>
  );
}

EmailFormVariant.propTypes = Props;
EmailFormVariant.defaultProps = DefaultProps;
