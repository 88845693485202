import { makeStyles } from '@mui/styles';
import { COLOR_PINK, COLOR_PURPLE_HOVER } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
    width: '100%',
    '& > div': {
      width: '100%',
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
    },
  },
  input: {
    maxWidth: '100%',
    width: '100%',
    marginBottom: theme.spacing(2),
    color: (props) => (props.labelColor === 'white' ? '#ffffff !important' : '#616161 !important'),

    '&:hover': {
      '&::before': {
        borderColor: `${theme.palette.grey[400]} !important`,
      },
    },

    '&::before, &::after': {
      borderColor: `${theme.palette.grey[400]} !important`,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(2),
    },
  },
  inputFocused: {
    '&::after': {
      borderColor: `${theme.palette.grey[400]} !important`,
    },
  },
  inputLabel: (props) => ({
    color: props.labelColor === 'white' ? theme.palette.common.white : theme.palette.grey[700],
  }),
  inputLabelFocused: (props) => ({
    color: props.labelColor === 'white' ? '#ffffff !important' : '#616161 !important',
  }),
  button: {
    padding: theme.spacing(1.5, 3),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 35,
    color: '#fff !important',
    width: '100%',

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(2),
    },
  },
  primaryButton: {
    color: '#fff !important',
    backgroundColor: '#7833FF',
    borderRadius: 40,

    '&:hover': {
      backgroundColor: '#5F29CC',
    },
  },
  whiteButton: {
    backgroundColor: theme.palette.common.white,
    color: '#2B2A2F',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: '#7833FF',
    },
  },
  buttonTextPurple: {
    color: '#fff !important',
    backgroundColor: '#7833FF',

    '& > span': {
      color: '#fff !important',
    },

    '&:hover': {
      backgroundColor: '#5F29CC',
    },
  },
  buttonLogged: {
    backgroundColor: '#7833FF',
    color: '#fff',
    padding: theme.spacing(1.5, 4),
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 35,
    width: '100%',
    marginTop: theme.spacing(2),

    '&:hover': {
      backgroundColor: COLOR_PURPLE_HOVER,
      color: theme.palette.common.white,
    },
  },
  btnUserPink: {
    backgroundColor: COLOR_PINK,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: COLOR_PINK,
      color: theme.palette.common.white,
    },
    '&:disabled': {
      color: theme.palette.common.white,
    },
  },
  btnLoggedDefault: {
    backgroundColor: '#7833FF',
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: COLOR_PURPLE_HOVER,
      color: theme.palette.common.white,
    },
  },
  btnWhite: {
    backgroundColor: theme.palette.common.white,
    color: '#7833FF !important',

    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      color: '#7833FF !important',
    },
  },
}));
