import { useState, useEffect } from 'react';
import { getEnv } from 'utils';

/**
 * Hook to fetch and use Google Analytics IDs
 * @param {string} measurementId - The Google Analytics measurement ID
 * @param {number} timeout - Maximum time to wait for IDs in milliseconds
 * @returns {{gaIdentifiers: {client_id: string, session_id: string} | null, isGALoading: boolean, error: Error | null}}
 */
export function useGoogleAnalytics(timeout = 5000) {
  const measurementId = getEnv('GA_MEASUREMENT_ID');
  const [gaIdentifiers, setGaIdentifiers] = useState(null);
  const [isGALoading, setIsGALoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Skip on server-side
    if (typeof window === 'undefined') {
      setIsGALoading(false);
      setError(new Error('Window object is not available'));
      return;
    }

    let timeoutId;

    // Initialize required GA objects
    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function () {
        window.dataLayer.push(arguments);
      };

    // Important: This nesting of callbacks is intentional to match the original flow
    // Google Analytics might require the sequence to happen in this specific order
    const fetchAnalyticsIds = () => {
      // Set timeout for the entire operation
      timeoutId = setTimeout(() => {
        setError(new Error(`Analytics IDs request timed out after ${timeout}ms`));
        setIsGALoading(false);
      }, timeout);

      try {
        // Get client ID first
        window.gtag('get', measurementId, 'client_id', (clientId) => {
          if (!clientId) {
            clearTimeout(timeoutId);
            setError(new Error('Failed to retrieve client ID'));
            setIsGALoading(false);
            return;
          }

          // Then get session ID
          window.gtag('get', measurementId, 'session_id', (sessionId) => {
            clearTimeout(timeoutId);

            if (!sessionId) {
              setError(new Error('Failed to retrieve session ID'));
              setIsGALoading(false);
              return;
            }

            // Set both IDs if successful
            setGaIdentifiers({
              client_id: clientId,
              session_id: sessionId,
            });

            setIsGALoading(false);
          });
        });
      } catch (err) {
        clearTimeout(timeoutId);
        setError(err);
        setIsGALoading(false);
      }
    };

    // Give Google Analytics a moment to initialize if needed
    const initTimeout = setTimeout(() => {
      fetchAnalyticsIds();
    }, 100);

    // Cleanup
    return () => {
      clearTimeout(initTimeout);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [measurementId, timeout]);

  return { gaIdentifiers, isGALoading, error };
}
